import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { FaChevronRight } from "react-icons/fa"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import StaffCard from "../components/StaffCard"
import Syllabics from "../components/Syllabics"
import BlockContentPrimary from "../components/blockContentPrimary"
import StaffFormOverlay from "../components/staffForm/staffFormOverlay"
import ContactFormStaff from "../components/staffForm/contactFormStaff"

import Files from "../components/Files"
import DisplayPosts from "../components/DisplayPosts"
import Hero from "../components/Hero"
import ButtonTemplate from "../components/buttons/ButtonTemplate"
import GatsbyImage from "gatsby-image"

const DepartmentPageTemplate = ({ data }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [contactId, setContactId] = useState("")
  const [contactName, setContactName] = useState("")

  const { subDepartments } = data

  const blogPosts = data.allSanityBlogPost.edges
  const {
    departmentDescription,
    departmentName,
    departmentIcon,
    syllabicsTitle = "",
    _rawMainContent,
    files,
    heroImage,
    slug,
    lowerImage,
    externalLinks,
  } = data.sanityDepartments
  const staffMembers = data.allSanityStaff.edges

  const backgroundImage = heroImage ? heroImage.asset : null

  const [postCount, setpostCount] = useState(3)

  let displayBlogPosts = blogPosts.slice(0, postCount)

  const defaultIcon = data.defaultIcon.childImageSharp

  const increasePostCount = () => {
    return setpostCount(postCount + 5)
  }
  return (
    <Layout>
      <SEO title={departmentName} />
      <Hero
        bgClassName=""
        heroImage={backgroundImage}
        syllabics={syllabicsTitle}
        englishTitle={departmentName}
      />

      {/* Main Content Section */}
      <section className="flex flex-col items-center justify-center mt-12 ">
        <div className="mx-5 max-w-7xl overflow-hidden">
          {_rawMainContent && (
            <>
              <BlockContentPrimary blockData={_rawMainContent} />
              {/* Files Section */}
              {files.length >= 1 && <Files files={files} />}
              {/* End of Files Section */}
            </>
          )}
          {/* LIST OF SUB-DEPTS and External Links */}

          <div className="w-full grid gap-y-5 pt-5">
            {/* Sub Departments */}
            {subDepartments.nodes.length >= 1 && (
              <>
                <div className="grid">
                  <div className=" grid gap-y-5 justify-items-start  justify-self-start ml-8 md:ml-14">
                    {subDepartments.nodes.map((subDept, index) => (
                      <ButtonTemplate
                        text={subDept.departmentName}
                        to={`/departments/${subDept.slug.current}`}
                        triangleFill="rgb(131,204,186)"
                        bannerBg="rgb(178,236,221)"
                      />
                    ))}
                  </div>
                </div>
              </>
            )}

            {/* END of Sub Departments */}

            {/* External Links  */}

            {!!externalLinks.length && (
              <div className="grid gap-y-5 justify-items-start  justify-self-start ml-8 md:ml-14">
                {externalLinks.map((link, index) => (
                  <ButtonTemplate
                    text={`${link.title}`}
                    to={link.url}
                    triangleFill="rgb(131,204,186)"
                    bannerBg="rgb(178,236,221)"
                    key={`${link._key}-${index}`}
                  />
                ))}
              </div>
            )}
            {/* END of External Links  */}
          </div>
        </div>
      </section>
      {/* End of Main Content Section */}

      {/* Staff section */}
      <section>
        {/* master contact form overlay */}
        <StaffFormOverlay isVisible={showOverlay} setIsVisible={setShowOverlay}>
          <ContactFormStaff
            setIsVisible={setShowOverlay}
            staffId={contactId}
            staffName={contactName}
          />
        </StaffFormOverlay>

        {/* master contact form overlay */}

        {staffMembers.length > 0 && (
          <SectionTitle>
            <span className=" text-3xl leading-none">
              {/* <Syllabics className="text-red-light">kErdfdg</Syllabics> */}
              <Syllabics className="text-red-light">ᔑᐸᐧᑲᒪ ᐊᓄᑭᓇᑲᓇᐠ</Syllabics>
              <br />
              {departmentName} Staff
            </span>
          </SectionTitle>
        )}

        <div className="grid text-center justify-stretch mb-10">
          <div
            className="grid justify-center gap-y-10 mx-5 md:gap-y-20 md:gap-x-5  my-10"
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(300px,500px))",
            }}
          >
            {staffMembers.map(member => {
              const {
                id,
                name,
                email,
                jobTitle,
                phoneNumber,
                _id,
              } = member.node
              return (
                <StaffCard
                  name={`${name}`}
                  jobTitle={jobTitle}
                  phoneNumber={phoneNumber}
                  key={`deptpage-${id}`}
                  setShowOverlay={setShowOverlay}
                  setContactId={setContactId}
                  setContactName={setContactName}
                  showOverlay={showOverlay}
                  id={_id}
                  email={email}
                />
              )
            })}
          </div>
        </div>
      </section>
      {/* End of Staff Section */}

      {/* KR requested an image to sit below the staff section of the education/production page.  This enables it for all dept pages. Data is provided in Sanity (hidden field). */}
      {lowerImage && (
        <div className="grid justify-items-center mb-12">
          <GatsbyImage
            className="w-full max-w-6xl"
            fluid={lowerImage.asset.fluid}
          />
        </div>
      )}

      {/* News Section */}

      <SectionTitle>
        <Syllabics className="text-red-light">ᐅᐢᑭ ᐊᒋᒧᐃᐧᐣ</Syllabics>
        <br />
        {departmentName} News
      </SectionTitle>
      <DisplayPosts posts={displayBlogPosts} />

      {/* Spacer */}
      <div className="mb-5"></div>

      {/* End of News Section */}
    </Layout>
  )
}

export const query = graphql`
  query departmentPageQuery($slug: String!, $id: String!) {
    sanityDepartments(slug: { current: { eq: $slug } }) {
      id
      departmentName
      departmentDescription
      slug {
        current
      }
      _rawMainContent(resolveReferences: { maxDepth: 5 })
      syllabicsTitle
      departmentIcon {
        asset {
          assetId
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      files {
        asset {
          id
          url
          originalFilename
        }
      }
      heroImage {
        asset {
          fluid(maxWidth: 1800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      externalLinks {
        _key
        title
        url
      }
      lowerImage {
        asset {
          fluid(maxWidth: 1200) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityBlogPost(
      filter: {
        refs: { departmentReference: { elemMatch: { id: { eq: $id } } } }
      }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...DisplayPostsFragment
        }
      }
    }
    allSanityStaff(
      sort: { fields: [weight, name], order: ASC }
      filter: {
        department: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
    ) {
      edges {
        node {
          ...StaffCard
        }
      }
    }

    defaultIcon: file(relativePath: { eq: "icons/Health-Icon.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subDepartments: allSanityDepartments(
      filter: { departmentType: { parentDepartment: { id: { eq: $id } } } }
      sort: { order: ASC, fields: departmentName }
    ) {
      nodes {
        departmentName
        id
        slug {
          current
        }
      }
    }
  }
`

export default DepartmentPageTemplate
